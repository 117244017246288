import { ApiService } from '~plugins/axios/api';
import axiosService from '~plugins/axios';
import { IBodyResponse, ICsvFile, IGetListResponse } from '~common/interfaces';
import {
    IMarketingChannel,
    IMarketingChannelGetListQuery,
    IMarketingChannelExportCsvQuery,
    IMarketingChannelBulkDeleteItem,
    IMarketingChannelBulkCreateFormItem,
    IMarketingChannelBulkUpdateFormItem,
    IMarketingChannelDropDownQuery,
    IMarketingChannelDropdown,
} from '../interfaces';
class MarketingChannelService extends ApiService {
    getMarketingChannelListForDropdown(params?: IMarketingChannelDropDownQuery) {
        return this._get(`${this.baseUrl}/dropdown`, {
            params,
        }) as Promise<IBodyResponse<IGetListResponse<IMarketingChannelDropdown>>>;
    }

    getList(queryString: IMarketingChannelGetListQuery) {
        return this._getList(queryString) as Promise<
            IBodyResponse<IGetListResponse<IMarketingChannel>>
        >;
    }

    getListByIds(ids: number[]) {
        return this._get(`${this.baseUrl}/get-by-ids`, {
            params: {
                ids,
            },
        }) as Promise<IBodyResponse<IMarketingChannel[]>>;
    }

    getDetail(id: number) {
        return this._getDetail(id) as Promise<IBodyResponse<IMarketingChannel>>;
    }

    bulkCreate(bulkCreateBody: IMarketingChannelBulkCreateFormItem[]) {
        return this._post(`${this.baseUrl}/bulk-create`, {
            items: bulkCreateBody,
        }) as Promise<IBodyResponse<IMarketingChannel>>;
    }

    bulkUpdate(bulkUpdateBody: IMarketingChannelBulkUpdateFormItem[]) {
        return this._patch(`${this.baseUrl}/bulk-update`, {
            items: bulkUpdateBody,
        }) as Promise<IBodyResponse<IMarketingChannel>>;
    }

    bulkDelete(bulkDeleteBody: IMarketingChannelBulkDeleteItem) {
        return this._delete(`${this.baseUrl}/bulk-delete`, {
            data: bulkDeleteBody,
        }) as Promise<IBodyResponse<boolean>>;
    }

    exportCsv(query: IMarketingChannelExportCsvQuery) {
        return this._post(`${this.baseUrl}/export`, query) as Promise<
            IBodyResponse<ICsvFile>
        >;
    }

    getMarketingChannelCredentialById(id: number) {
        return this._get(`${this.baseUrl}/${id}/credential`) as Promise<
            IBodyResponse<IMarketingChannel[]>
        >;
    }
}

export const marketingChannelService = new MarketingChannelService(
    { baseUrl: '/marketing-channel' },
    axiosService,
);
