import { Button, Pagination, Table } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import './RoomBookingCancelationInfoTabPane.scss';
import { useAppDispatch, useAppSelector } from '~hooks';
import {
    getOtaCancellationLogs,
    selectedRoomBookingDetailSelector,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { IGetOtaCancellationLog } from '~features/room-booking/interfaces';
import dayjs from 'dayjs';

function RoomBookingCancelationInfoTabPane() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const PAGE_SIZE = 10;
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const {
        isLoadingOtaCancellationLogs: loading,
        selectedRoomBookingOtaCancellationLogs: logs,
        selectedRoomBookingOtaCancellationLogsTotal: total,
    } = useAppSelector((state) => state.roomBooking);
    const [page, setPage] = useState(1);
    const cancelationInfoColumns = [
        {
            key: 'date',
            title: t('roomBooking.detail.otaCancellationLogColumns.date'),
            render: (item: IGetOtaCancellationLog) => (
                <div>{dayjs(item.createdAt).format('YYYY/MM/DD HH:mm:ss')}</div>
            ),
            width: '200px',
        },
        {
            key: 'status',
            title: t('roomBooking.detail.otaCancellationLogColumns.status'),
            render: (item: IGetOtaCancellationLog) => (
                <div>{t(`roomBooking.detail.otaCancelation.status.${item.status}`)}</div>
            ),
            width: '250px',
        },
        {
            key: 'message',
            title: t('roomBooking.detail.otaCancellationLogColumns.message'),
            render: (item: IGetOtaCancellationLog) => <div>{item.description}</div>,
        },
    ];

    const _getLogs = useCallback(async (page: number) => {
        await dispatch(
            getOtaCancellationLogs({
                id: selectedRoomBookingDetail?.id as number,
                parameters: { page, limit: PAGE_SIZE },
            }),
        );
    }, []);

    useEffect(() => {
        _getLogs(page);
    }, [page]);

    return (
        <>
            <div className="cancelation-info__card">
                <div className="cancelation-info__card-grid">
                    <div>{t('roomBooking.detail.otaCancelation.ota')}</div>
                    <div>{selectedRoomBookingDetail?.marketingChannel?.name}</div>
                    <div>{t('roomBooking.detail.otaCancelation.fee')}</div>
                    <div>
                        {selectedRoomBookingDetail?.otaCancellationRate !== null ? (
                            selectedRoomBookingDetail?.otaCancellationRate === 0 ? (
                                t('roomBooking.detail.otaCancelation.free')
                            ) : (
                                t('roomBooking.detail.otaCancelation.paid')
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                    <div>{t('roomBooking.detail.otaCancelation.memoLabel')}</div>
                    <div>{selectedRoomBookingDetail?.otaCancellationMemo}</div>
                    <div>{t('roomBooking.detail.otaCancelation.cancelledBy')}</div>
                    <div>{selectedRoomBookingDetail?.otaCancelledByEmail}</div>
                </div>
            </div>
            <div className="cancelation-info__button-wrapper">
                <Button onClick={() => _getLogs(page)}>
                    <ReloadOutlined />
                </Button>
            </div>
            <div className="cancelation-info__table">
                <Table
                    columns={cancelationInfoColumns}
                    dataSource={logs}
                    rowKey="key"
                    pagination={false}
                    loading={loading}
                />
                <div className="cancelation-info__pagination">
                    {total > PAGE_SIZE && (
                        <Pagination
                            align="end"
                            defaultCurrent={page}
                            current={page}
                            total={total}
                            pageSize={PAGE_SIZE}
                            onChange={(value) => setPage(value)}
                            showSizeChanger={false}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default RoomBookingCancelationInfoTabPane;
