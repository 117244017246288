import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { marketingChannelCredentialService } from '../services/marketing-channel-credential.api';
import { IMarketingChannelCredentialState } from '../interfaces';

const initialState: IMarketingChannelCredentialState = {
    credential: null,
    loading: false,
};
export const getMarketingChannelCredential = createAsyncThunk(
    'marketing-channel-credential/getMarketingChannelCredential',
    async (marketingChannelId: number) => {
        return await marketingChannelCredentialService.getMarketingChannelCredentialByMarketingId(
            marketingChannelId,
        );
    },
);

export const marketingChannelCredentialSlice = createSlice({
    name: 'marketing-channel-credential',
    initialState,
    reducers: {
        setCredential: (state, action) => {
            state.credential = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMarketingChannelCredential.pending, (state) => {
            state.credential = null;
            state.loading = true;
        });
        builder.addCase(getMarketingChannelCredential.fulfilled, (state, action) => {
            state.credential = action.payload.data;
            state.loading = false;
        });
        builder.addCase(getMarketingChannelCredential.rejected, (state) => {
            state.credential = null;
            state.loading = false;
        });
    },
});

export const { setCredential } = marketingChannelCredentialSlice.actions;

export default marketingChannelCredentialSlice.reducer;
