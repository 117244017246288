import { Button, Form, Modal, notification } from 'antd';
import { InputText } from '~components';
import './OTACancelationRobotModal.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { otaAccountSettingSchema } from '~features/room-booking/schema';
import { useMutation } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '~hooks';
import { marketingChannelCredentialService } from '~features/marketing-channel-credential/services/marketing-channel-credential.api';
import { selectedRoomBookingDetailSelector } from '~features/room-booking/reducers/room-booking.reducer';
import { IMarketingChannelCredentialBody } from '~features/marketing-channel-credential/interfaces';
import { setCredential } from '~features/marketing-channel-credential/reducers/marketing-channel-credential.reducer';

type Prop = {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
};

function OTACancelationRobotModal({ isModalOpen, setIsModalOpen }: Prop) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const { credential } = useAppSelector((state) => state.marketingChannelCredential);
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            username: credential?.username || '',
            password: '',
        },
        resolver: yupResolver(otaAccountSettingSchema),
        mode: 'onChange',
    });

    const { mutateAsync, isPending } = useMutation({
        mutationFn: async (data: IMarketingChannelCredentialBody) => {
            if (!selectedRoomBookingDetail?.marketingChannelId) throw new Error();
            let response;
            if (!credential) {
                response = await marketingChannelCredentialService.create(
                    selectedRoomBookingDetail?.marketingChannelId,
                    data,
                );
            } else {
                response = await marketingChannelCredentialService.update(
                    selectedRoomBookingDetail.id,
                    credential?.id,
                    data,
                );
            }
            if (response.success) {
                return response.data;
            }
            throw new Error(response.message);
        },
        onSuccess: (data) => {
            notification.success({
                message: t('roomBooking.detail.message.otaCredentialUpdateSuccess'),
            });
            dispatch(setCredential(data));
            setIsModalOpen(false);
        },
        onError: (error) => {
            notification.error({
                message: t('roomBooking.detail.message.otaCredentialUpdateFailed'),
                description: error.message,
            });
        },
    });

    const submit = () => {
        handleSubmit(async (data) => {
            const { username, password } = data;
            await mutateAsync({ username, password });
        })();
    };

    return (
        <Modal
            title={t('roomBooking.detail.otaCancelation.AdminSetting')}
            open={isModalOpen}
            centered
            onCancel={() => setIsModalOpen(false)}
            width={440}
            className="cancelation-modal"
            footer={[
                <Button
                    key="close"
                    onClick={() => setIsModalOpen(false)}
                    size="large"
                    className="cancelation-modal__action-btn"
                >
                    {t('roomBooking.detail.otaCancelation.close')}
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={submit}
                    size="large"
                    disabled={!formState.isValid || isPending}
                    className="cancelation-modal__action-btn"
                >
                    {t('roomBooking.detail.otaCancelation.save')}
                </Button>,
            ]}
        >
            <Form layout="vertical">
                <div className="cancelation-modal__wrapper">
                    <InputText
                        label={t('roomBooking.detail.otaCancelation.account')}
                        placeholder={t('roomBooking.detail.otaCancelation.usernameEmail')}
                        autoComplete="username"
                        name="username"
                        control={control}
                        id="ota-cancelation-form-username"
                        required={true}
                    />
                    <InputText
                        label={t('roomBooking.detail.otaCancelation.password')}
                        placeholder={t('roomBooking.detail.otaCancelation.password')}
                        autoComplete="password"
                        name="password"
                        control={control}
                        type="password"
                        id="ota-cancelation-form-password"
                        required={true}
                    />
                </div>
            </Form>
        </Modal>
    );
}

export default OTACancelationRobotModal;
