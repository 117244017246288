import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { appReducer } from '~common/reducers/app.reducer';
import loginReducer from '~features/auth/reducers/login.reducer';
import { childrenTypeReducer } from '~features/children-type/reducers/children-type.reducer';
import { childrenReducer } from '~features/children-type/reducers/children.reducer';
import closingBookingReducer from '~features/closing-booking/reducers/closing-booking.reducer';

import facilityBookingListReducer from '~features/facility-booking/reducers/facility-booking.reducer';
import { facilityBookingScheduleReducer } from '~features/facility-booking/reducers/facility-schedule.reducer';
import facilityTypeReducer from '~features/facility-type/facility-type.reducer';
import facilityReducer from '~features/facility/facility.reducer';
import guestClassificationReducer from '~features/guest-classification/guest-classification.reducer';
import guestReducer from '~features/guest/reducers/guest.reducer';
import historyListReducer from '~features/fo-operation-history/reducers/history-list.reducer';
import boHistoryReducer from '~features/bo-operation-history/reducers/history-list.reducer';
import hotelReducer from '~features/hotel/hotel.reducer';
import indicatorReducer from '~features/indicator/reducers/indicator.reducer';
import inventoryStopSellingReducer from '~features/inventory/reducers/inventory-stop-selling.reducer';
import inventoryReducer from '~features/inventory/reducers/inventory.reducer';
import marketingChannelReducer from '~features/marketing-channel/marketing-channel.reducer';
import notificationReducer from '~features/admin-notification/reducers/notification.reducer';
import { paymentMethodReducer } from '~features/payment-method/reducers/paymentMethod.reducer';
import { planReducer } from '~features/plan/reducers/plan.reducer';
import rankCalendarReducer from '~features/rank-calendar/reducers/rank-calendar.reducer';
import createBookingReducer from '~features/room-booking/reducers/create-booking.reducer';
import printBookingReducer from '~features/room-booking/reducers/print-booking.reducer';
import roomBookingReducer from '~features/room-booking/reducers/room-booking.reducer';
import roomBookingSchedule from '~features/room-booking/reducers/schedule.reducer';
import printRoomCleaningReducer from '~features/room-cleaning/reducers/print-room-cleaning.reducer';
import roomCleaningReducer from '~features/room-cleaning/reducers/room-cleaning.reducer';
import roomManagementReducer from '~features/room-management/reducers/room-management.reducer';
import saleGroupReducer from '~features/sale-group/reducers/sale-group.reducer';
import saleItemReducer from '~features/sale-item/reducers/sale-item.reducer';
import { siteControllerReducer } from '~features/site-controller/reducers/site-controller.reducer';
import tagReducer from '~features/tag/reducers/tag.reducer';
import taxReducer from '~features/tax/reducers/tax.reducer';
import marketingChannelGroupReducer from '../../features/marketing-channel-group/marketing-channel-group.reducer';
import roomTypeReducer from '../../features/room-type/room-type.reducer';
import roomReducer from '../../features/room/room.reducer';
import { settingListenerMiddleware } from './middleware/settings';
import splitRoomBookingReceiptReducer from '../../features/room-booking/reducers/split-receipt.reducer';
import dashboardReducer from '~features/dashboard/reducers/dashboard.reducer';
import splitFacilityBookingReceiptReducer from '../../features/facility-booking/reducers/split-receipt.reducer';
import salesPaymentReducer from '~features/report/reducers/report-sales-payment.reducer';
import reportSummaryReducer from '~features/report/reducers/report.reducer';
import salesSummaryReducer from '~features/report/reducers/sales-summary.reducer';
import salesByCategoryReducer from '~features/report/reducers/sales-by-category.reducer';
import unpaidDetailReducer from '~features/report/reducers/unpaid-detail.reducer';
import paymentSummaryReducer from '~features/report/reducers/payment-summary.reducer';
import reportCancellationReducer from '~features/report/reducers/report-cancellation.reducer';
import reportOperationStatusReducer from '~features/report/reducers/report-operation-status.reducer';
import paymentDetailsReducer from '~features/report/reducers/report-payment-details.reducer';
import smtpSettingReducer from '~features/smtp-setting/reducers/smtp-setting.reducer';
import printFacilityBookingReducer from '~features/facility-booking/reducers/print-facility-booking.reducer';
import localTaxDetailReducer from '~features/report/reducers/local-tax-detail.reducer';
import currentGuestReducer from '~features/report/reducers/current-guest.reducer';
import mealReportReducer from '~features/report/reducers/meal-report.reducer';
import integrationsReducer from '~features/integrations/reducers/integrations.reducer';
import csvBookingUploadReducer from '~features/csv-booking-upload/reducers/csv-booking-upload.reducer';
import salesByPlansReducers from '~features/report/reducers/sales-by-plans.reducer';
import salesByOtaReducer from '~features/report/reducers/sales-by-ota.reducer';
import marketingChannelCredentialReducer from '~features/marketing-channel-credential/reducers/marketing-channel-credential.reducer';
export function makeStore() {
    return configureStore({
        reducer: {
            app: appReducer,
            children: childrenReducer,
            childrenType: childrenTypeReducer,
            plan: planReducer,
            createBooking: createBookingReducer,
            printBooking: printBookingReducer,
            guest: guestReducer,
            login: loginReducer,
            room: roomReducer,
            roomType: roomTypeReducer,
            marketingChannelGroup: marketingChannelGroupReducer,
            roomBooking: roomBookingReducer,
            roomBookingSchedule: roomBookingSchedule,
            facilityType: facilityTypeReducer,
            facility: facilityReducer,
            marketingChannel: marketingChannelReducer,
            marketingChannelCredential: marketingChannelCredentialReducer,
            facilityBookingSchedule: facilityBookingScheduleReducer,
            hotel: hotelReducer,
            facilityBooking: facilityBookingListReducer,
            guestClassification: guestClassificationReducer,
            rankCalendar: rankCalendarReducer,
            saleItem: saleItemReducer,
            saleGroup: saleGroupReducer,
            paymentMethod: paymentMethodReducer,
            indicator: indicatorReducer,
            tax: taxReducer,
            closingBooking: closingBookingReducer,
            roomManagement: roomManagementReducer,
            roomCleaning: roomCleaningReducer,
            printRoomCleaning: printRoomCleaningReducer,
            inventory: inventoryReducer,
            inventoryStopSelling: inventoryStopSellingReducer,
            notification: notificationReducer,
            tag: tagReducer,
            history: historyListReducer,
            siteController: siteControllerReducer,
            splitRoomBookingReceipt: splitRoomBookingReceiptReducer,
            dashboard: dashboardReducer,
            splitFacilityBookingReceipt: splitFacilityBookingReceiptReducer,
            reportSalesByCategory: salesByCategoryReducer,
            reportSummary: reportSummaryReducer,
            reportSalesPayment: salesPaymentReducer,
            reportSalesSummary: salesSummaryReducer,
            reportUnpaidDetail: unpaidDetailReducer,
            reportLocalTaxDetail: localTaxDetailReducer,
            reportPaymentSummary: paymentSummaryReducer,
            reportCancellation: reportCancellationReducer,
            reportOperationStatus: reportOperationStatusReducer,
            reportPaymentDetails: paymentDetailsReducer,
            reportCurrentGuest: currentGuestReducer,
            mealReport: mealReportReducer,
            reportSalesByPlans: salesByPlansReducers,
            reportSalesByOta: salesByOtaReducer,
            smtpSetting: smtpSettingReducer,
            integrations: integrationsReducer,
            boHistory: boHistoryReducer,
            printFacilityBooking: printFacilityBookingReducer,
            csvBookingUploadStore: csvBookingUploadReducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these action types
                    ignoredActions: ['room-booking/setBookingInfo'],
                    // Ignore these field paths in all actions
                    ignoredActionPaths: ['meta.arg'],
                    // Ignore these paths in the state
                    ignoredPaths: ['createBooking.bookingInfo.files'],
                },
            }).concat([settingListenerMiddleware.middleware]),
        devTools: process.env.NODE_ENV !== 'production',
    });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action<string>
>;

export default store;
