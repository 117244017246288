import {
    IUpdateRoomBookingItem,
    IUpdateBookingFormData,
    IUpdateRoomBookingReceipt,
    IGetMarketingChannelListForDropdownQuery,
    IGetPreparationList,
    IAutoAssignBookingToRoomItem,
    IRoomBookingItem,
    IUpdateBookingItemNoShowStatus,
    IGetOtaCancellationLogParameters,
} from './../interfaces';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import trim from 'lodash/trim';
import { marketingChannelService } from '~features/marketing-channel/services/marketing-channel.api';
import { AppState } from '~plugins/redux-toolkit/store';
import {
    DEFAULT_FIRST_PAGE,
    DEFAULT_LIMIT_FOR_PAGINATION,
} from '../../../common/constants';
import {
    DateType,
    RoomBookingDetailPageTabPane,
    RoomBookingItemBookingStatus,
    roomBookingListQueryDefault,
    SearchField,
    SelfCheckInStatus,
} from '../constants';
import {
    IAdvanceSearchGetListQuery,
    IRoomBookingGetListQuery,
    IRoomBookingState,
    IUpdateBookingItemStatus,
} from '../interfaces';
import { roomBookingService } from '../services/room-booking.service';
import { IBodyResponse, IGetListResponse } from '~common/interfaces';
import { getStatisticByDateV2 } from './schedule.reducer';
import { convertDatePeriodInRoomBookingQuery } from '../helper';
import { roomBookingHotelSmart } from '../services/room-booking-hs.service';
import { roomBookingServiceV2 } from '../services/room-bookingV2.service';

const initialState: IRoomBookingState = {
    roomBookingListQuery: {
        ...roomBookingListQueryDefault,
        dateType: DateType.TODAY,
    },
    hsRoomBookingUrlDetail: {
        hasCheckedInURL: false,
        guestPhone: '',
        guestEmail: '',
        selfCheckInStatus: SelfCheckInStatus.NOT_ARRIVED,
        keyCodes: [],
        billings: [],
        payments: [],
    },
    hsEditHistory: {
        itemsLen: 0,
        totalItemsLen: 0,
        data: [],
    },
    isHsUrlDetailSync: false,
    totalRoomBookings: 0,
    isShowReservationForm: false,
    isShowAdvanceSearchForm: false,
    isShowAdvanceSearchTags: false,
    marketingChannelDropDowns: [],
    advanceSearchGetListQuery: null,
    roomBookingList: [],
    showLoading: false,
    isUpdatingBooking: false,
    searchField: SearchField.ALL,
    isAdvanceSearch: false,
    selectedRoomBookingDetail: null,
    isLoadingOtaCancellationLogs: false,
    selectedRoomBookingOtaCancellationLogs: [],
    selectedRoomBookingOtaCancellationLogsTotal: 0,
    roomBookingReceipt: null,
    canEdit: true,
    isShowUpdateReceiptLoading: false,
    activeDetailTabPane: RoomBookingDetailPageTabPane.ROOM_BOOKING_LIST_TAB_PANE,
    isShowPaymentModal: false,
    isShowAccommodationCardPrintingModal: false,
    showPreparationListForm: false,
    roomBookingPreparationList: [],
    showLoadingPeparationListTable: false,
    isAutoAssignRoomLoading: false,
    isBulkUpdateStatusLoading: false,
    isUpdatingNoShowStatus: false,
    bookingSearchKeyword: '',
    isShowInputSearch: false,
    dateTypeBookingSearch: DateType.TODAY,
    bookingIteamSearchStatus: RoomBookingItemBookingStatus.ALL,
    isShowBulkPaymentGroupBookingModal: false,
};

export const fetchRoomBookingList = createAsyncThunk(
    'room-booking',
    async (query: IRoomBookingGetListQuery | IAdvanceSearchGetListQuery) => {
        return await roomBookingService.getList(query);
    },
);

export const fetchMarketingChannelDropDown = createAsyncThunk(
    'marketing-channel/dropdown',
    async (query: IGetMarketingChannelListForDropdownQuery) => {
        return await marketingChannelService.getMarketingChannelListForDropdown({
            ...query,
            isTemporary: query.isPullFromTll || false,
        });
    },
);

export const updateBookingItemStatus = createAsyncThunk(
    'room-booking/updateBookingItemStatus',
    async (body: IUpdateBookingItemStatus, { dispatch }) => {
        let response:
            | IBodyResponse<IGetListResponse<IRoomBookingItem>>
            | Record<string, unknown> = {};
        response = await roomBookingService.updateBookingItemStatus(body);
        dispatch(getStatisticByDateV2());
        return response as IBodyResponse<IGetListResponse<IRoomBookingItem>>;
    },
);

export const updateBookingItemNoShowStatus = createAsyncThunk(
    'room-booking/updateBookingItemNoShowStatus',
    async (body: IUpdateBookingItemNoShowStatus) => {
        let response:
            | IBodyResponse<IGetListResponse<IRoomBookingItem>>
            | Record<string, unknown> = {};
        response = await roomBookingService.updateBookingItemNoShowStatus(body);
        return response as IBodyResponse<IGetListResponse<IRoomBookingItem>>;
    },
);

export const fetchRoomBookingDetail = createAsyncThunk(
    'room-booking/fetchRoomBookingDetail',
    async (id: number) => {
        return await roomBookingService.getDetail(id);
    },
);

export const fetchHotelSmartUrlDetail = createAsyncThunk(
    'room-booking/fetchHotelSmartUrlDetail',
    async (id: number) => {
        return await roomBookingHotelSmart.getHSUrlDetail(id);
    },
);
export const fetchHotelSmartEditHitsory = createAsyncThunk(
    'room-booking/fetchHotelSmartEditHitsory',
    async (id: number) => {
        return await roomBookingHotelSmart.getHSEditHistory(id);
    },
);

export const fetchOtaCancellationLog = createAsyncThunk(
    'room-booking/fetchOtaCancellationLog',
    async (id: number) => {
        return await roomBookingHotelSmart.getHSEditHistory(id);
    },
);

export const updateBooking = createAsyncThunk(
    'updateBooking/updateBooking',
    async ({
        id,
        formData,
    }: {
        id: number;
        formData: IUpdateBookingFormData | FormData;
    }) => {
        return await roomBookingService.updateBooking(id, formData);
    },
);

export const updateRoomBookingItem = createAsyncThunk(
    'roomBooking/updateRoomBookingItem',
    async (updateRoomBookingItem: IUpdateRoomBookingItem) => {
        return await roomBookingService.updateRoomBookingItem(
            updateRoomBookingItem.id,
            updateRoomBookingItem.body,
        );
    },
);

export const getAvailableRooms = createAsyncThunk(
    'roomBooking/getAvailableRooms',
    async (payload: number[]) => {
        return await roomBookingService.getAvailableRooms(payload);
    },
);

export const assignBookingToRoom = createAsyncThunk(
    'roomBooking/assignBookingToRoom',
    async (payload: IAutoAssignBookingToRoomItem[]) => {
        return await roomBookingService.assignBookingToRoom(payload);
    },
);

export const updateRoomBookingReceipt = createAsyncThunk(
    'roomBooking/updateRoomBookingReceipt',
    async (updateRoomBookingReceipt: IUpdateRoomBookingReceipt) => {
        return await roomBookingService.updateReceipt(
            updateRoomBookingReceipt.id,
            updateRoomBookingReceipt.body,
        );
    },
);

export const deleteRoomBooking = createAsyncThunk(
    'roomBooking/deleteRoomBooking',
    async (id: number) => {
        return await roomBookingService.deleteRoomBooking(id);
    },
);

export const bulkDeleteRoomBookingItems = createAsyncThunk(
    'roomBooking/bulkDeleteRoomBookingItems',
    async (ids: number[]) => {
        return await roomBookingService.bulkDeleteRoomBookingItems(ids);
    },
);

export const getRoomBookingReceipt = createAsyncThunk(
    'roomBooking/getRoomBookingReceipt',
    async (id: number) => {
        return await roomBookingService.getReceipt(id);
    },
);
export const bulkDeleteReceiptItemDetail = createAsyncThunk(
    'roomBooking/bulkDeleteReceiptItemDetail',
    async (ids: number[]) => {
        return await roomBookingService.bulkDeleteReceiptItemDetail(ids);
    },
);

export const getRoomBookingPreparationList = createAsyncThunk(
    'room-booking/getRoomBookingPreparationList',
    async (query: IGetPreparationList) => {
        return await roomBookingService.getRoomBookingPreparationList(query);
    },
);

export const getOriginalBookingId = createAsyncThunk(
    'room-booking/getOriginalBookingId',
    async (id: number) => {
        return await roomBookingService.getOriginalBookingId(id);
    },
);

export const getOtaCancellationLogs = createAsyncThunk(
    'room-booking/getOtaCancellationLogs',
    async ({
        id,
        parameters,
    }: {
        id: number;
        parameters: IGetOtaCancellationLogParameters;
    }) => {
        return await roomBookingServiceV2.getOtaCancellationLog(id, parameters);
    },
);

export const roomBookingSlice = createSlice({
    name: 'room-booking',
    initialState,
    reducers: {
        setRoomBookingListQuery: (state, action) => {
            const query = convertDatePeriodInRoomBookingQuery(action.payload);
            state.roomBookingListQuery = query;
        },
        setHsUrlDetail: (state, action) => {
            state.hsRoomBookingUrlDetail = action.payload;
        },
        setHsEditHistory: (state, action) => {
            state.hsEditHistory = action.payload;
        },
        setIsHsUrlDetailSync: (state, action) => {
            state.isHsUrlDetailSync = action.payload;
        },
        changeRoomBookingStatusQuery: (state, action) => {
            const selectedStatus = action.payload;
            let query;
            if (selectedStatus === RoomBookingItemBookingStatus.ALL) {
                query = { roomBookingItemStatus: undefined };
            } else {
                query = { roomBookingItemStatus: [selectedStatus] };
            }
            state.isShowAdvanceSearchForm = false;
            if (state.isAdvanceSearch) {
                state.isAdvanceSearch = false;
                state.isShowAdvanceSearchTags = false;
                state.advanceSearchGetListQuery = null;
                query = { ...roomBookingListQueryDefault, ...query };
            } else {
                query = {
                    ...state.roomBookingListQuery,
                    page: DEFAULT_FIRST_PAGE,
                    ...query,
                };
            }
            state.roomBookingListQuery = query;
        },
        changeDateTypeQuery: (state, action) => {
            const selectedDateType = action.payload;
            let query;
            query = {
                dateType:
                    selectedDateType === DateType.ALL ? undefined : selectedDateType,
                [state.searchField || SearchField.ALL]: undefined,
            };
            state.isShowAdvanceSearchForm = false;
            if (state.isAdvanceSearch) {
                state.isAdvanceSearch = false;
                state.isShowAdvanceSearchTags = false;
                state.advanceSearchGetListQuery = null;
                query = { ...roomBookingListQueryDefault, ...query };
            } else {
                query = {
                    ...state.roomBookingListQuery,
                    page: DEFAULT_FIRST_PAGE,
                    ...query,
                };
            }
            state.roomBookingListQuery = query;
        },
        setKeyword: (state, action) => {
            const keyword = trim(action.payload.bookingSearchKeyword || '');
            const status =
                action.payload.status === RoomBookingItemBookingStatus.ALL
                    ? undefined
                    : [action.payload.status];
            const query: IRoomBookingGetListQuery = {
                limit: state.roomBookingListQuery.limit,
                orderBy: state.roomBookingListQuery.orderBy,
                orderDirection: state.roomBookingListQuery.orderDirection,
                page: DEFAULT_FIRST_PAGE,
                [state.searchField || SearchField.ALL]: keyword,
                roomBookingItemStatus: status,
            };
            state.roomBookingListQuery = query;
            state.isShowAdvanceSearchForm = false;
            if (state.isAdvanceSearch) {
                state.isAdvanceSearch = false;
                state.isShowAdvanceSearchTags = false;
                state.advanceSearchGetListQuery = null;
            }
        },
        setRoomBookingList: (state, action) => {
            state.roomBookingList = action.payload;
        },
        setTotalRoomBookings: (state, action) => {
            state.totalRoomBookings = action.payload;
        },
        setShowReservationForm: (state, action) => {
            state.isShowReservationForm = action.payload;
        },
        setShowAdvanceSearchForm: (state, action) => {
            state.isShowAdvanceSearchForm = action.payload;
        },
        setIsShowAdvanceSearchTags: (state, action) => {
            state.isShowAdvanceSearchTags = action.payload;
        },
        setIsShowInputSearch: (state, action) => {
            state.isShowInputSearch = action.payload;
        },
        setAdvanceSearchListQuery: (state, action) => {
            state.advanceSearchGetListQuery = action.payload;
        },
        setSearchField: (state, action) => {
            state.searchField = action.payload;
        },
        setIsAdvanceSearch: (state, action) => {
            state.isAdvanceSearch = action.payload;
        },
        setBookingSearchKeyword: (state, action) => {
            state.bookingSearchKeyword = action.payload;
        },
        setDateTypeBookingSearch: (state, action) => {
            state.dateTypeBookingSearch = action.payload;
        },
        setBookingItemSearchStatus: (state, action) => {
            state.bookingIteamSearchStatus = action.payload;
        },
        setSelectedRoomBookingDetail: (state, action) => {
            state.selectedRoomBookingDetail = action.payload;
        },
        resetRoomBookingListState: () => {
            return initialState;
        },
        resetSplitReceiptRoomBooking: (state) => {
            return {
                ...initialState,
                roomBookingListQuery: state.roomBookingListQuery,
                bookingSearchKeyword: state.bookingSearchKeyword,
                isShowInputSearch: state.isShowInputSearch,
                dateTypeBookingSearch: state.dateTypeBookingSearch,
                bookingIteamSearchStatus: state.bookingIteamSearchStatus,
            };
        },
        setActiveDetailTabPane: (state, action) => {
            state.activeDetailTabPane = action.payload;
        },
        setIsShowPaymentModal: (state, action) => {
            state.isShowPaymentModal = action.payload;
        },
        setIsShowAccommodationCardPrintingModal: (state, action) => {
            state.isShowAccommodationCardPrintingModal = action.payload;
        },
        clearListQuery: (state) => {
            state.advanceSearchGetListQuery = null;
            state.isAdvanceSearch = false;
            state.isShowAdvanceSearchTags = false;
            state.roomBookingListQuery = { ...roomBookingListQueryDefault };
        },
        setShowPreparationListForm: (state, action) => {
            state.showPreparationListForm = action.payload;
        },
        setRoomBookingPreparationList: (state, action) => {
            state.roomBookingPreparationList = action.payload;
        },
        setShowBulkPaymentGroupBookingModal: (state, action) => {
            state.isShowBulkPaymentGroupBookingModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMarketingChannelDropDown.fulfilled, (state, action) => {
            state.marketingChannelDropDowns = action.payload?.data?.items || [];
        });
        builder.addCase(fetchRoomBookingList.fulfilled, (state, action) => {
            state.showLoading = false;
            state.roomBookingList = action.payload?.data?.items || [];
            state.totalRoomBookings = action.payload?.data?.totalItems || 0;
        });
        builder.addCase(fetchRoomBookingList.pending, (state) => {
            state.showLoading = true;
        });
        builder.addCase(fetchRoomBookingDetail.pending, (state) => {
            state.showLoading = true;
            state.selectedRoomBookingDetail = null;
        });
        builder.addCase(updateRoomBookingItem.pending, (state) => {
            state.isUpdatingBooking = true;
        });
        builder.addCase(updateRoomBookingItem.fulfilled, (state) => {
            state.isUpdatingBooking = false;
        });
        builder.addCase(updateBooking.pending, (state) => {
            state.isUpdatingBooking = true;
        });
        builder.addCase(updateBooking.fulfilled, (state) => {
            state.isUpdatingBooking = false;
        });
        builder.addCase(fetchRoomBookingDetail.fulfilled, (state, action) => {
            state.showLoading = false;
            if (action.payload.data) {
                state.selectedRoomBookingDetail = action.payload.data;
            }
        });
        builder.addCase(bulkDeleteRoomBookingItems.pending, (state) => {
            state.showLoading = true;
        });
        builder.addCase(bulkDeleteRoomBookingItems.fulfilled, (state) => {
            state.showLoading = false;
        });
        builder.addCase(getRoomBookingReceipt.pending, (state) => {
            state.showLoading = true;
        });
        builder.addCase(getRoomBookingReceipt.fulfilled, (state, action) => {
            state.showLoading = false;
            state.roomBookingReceipt = action.payload?.data?.roomBookingReceipt || null;
        });
        builder.addCase(bulkDeleteReceiptItemDetail.pending, (state) => {
            state.isShowUpdateReceiptLoading = true;
        });
        builder.addCase(bulkDeleteReceiptItemDetail.fulfilled, (state) => {
            state.isShowUpdateReceiptLoading = false;
        });
        builder.addCase(updateRoomBookingReceipt.pending, (state) => {
            state.isShowUpdateReceiptLoading = true;
        });
        builder.addCase(updateRoomBookingReceipt.fulfilled, (state) => {
            state.isShowUpdateReceiptLoading = false;
        });
        builder.addCase(getRoomBookingPreparationList.pending, (state) => {
            state.showLoadingPeparationListTable = true;
        });
        builder.addCase(getRoomBookingPreparationList.fulfilled, (state, action) => {
            state.showLoadingPeparationListTable = false;
            state.roomBookingPreparationList = action.payload?.data?.items || [];
        });
        builder.addCase(getAvailableRooms.pending, (state) => {
            state.isAutoAssignRoomLoading = true;
        });
        builder.addCase(getAvailableRooms.fulfilled, (state) => {
            state.isAutoAssignRoomLoading = false;
        });
        builder.addCase(assignBookingToRoom.pending, (state) => {
            state.isAutoAssignRoomLoading = true;
        });
        builder.addCase(assignBookingToRoom.fulfilled, (state) => {
            state.isAutoAssignRoomLoading = false;
        });
        builder.addCase(updateBookingItemStatus.pending, (state) => {
            state.isBulkUpdateStatusLoading = true;
        });
        builder.addCase(updateBookingItemStatus.fulfilled, (state) => {
            state.isBulkUpdateStatusLoading = false;
        });
        builder.addCase(updateBookingItemNoShowStatus.pending, (state) => {
            state.isUpdatingNoShowStatus = true;
        });
        builder.addCase(updateBookingItemNoShowStatus.fulfilled, (state) => {
            state.isUpdatingNoShowStatus = false;
        });
        builder.addCase(getOtaCancellationLogs.pending, (state) => {
            state.isLoadingOtaCancellationLogs = true;
            state.selectedRoomBookingOtaCancellationLogs = [];
            state.selectedRoomBookingOtaCancellationLogsTotal = 0;
        });
        builder.addCase(getOtaCancellationLogs.fulfilled, (state, action) => {
            state.isLoadingOtaCancellationLogs = false;
            state.selectedRoomBookingOtaCancellationLogs = action.payload?.data?.logs;
            state.selectedRoomBookingOtaCancellationLogsTotal =
                action.payload?.data?.total;
        });
    },
});

export const {
    setRoomBookingListQuery,
    setTotalRoomBookings,
    setRoomBookingList,
    setKeyword,
    setShowReservationForm,
    setShowAdvanceSearchForm,
    setIsShowAdvanceSearchTags,
    setAdvanceSearchListQuery,
    setSearchField,
    setIsAdvanceSearch,
    setBookingSearchKeyword,
    setDateTypeBookingSearch,
    setBookingItemSearchStatus,
    setSelectedRoomBookingDetail,
    setIsShowInputSearch,
    resetRoomBookingListState,
    resetSplitReceiptRoomBooking,
    setActiveDetailTabPane,
    clearListQuery,
    changeRoomBookingStatusQuery,
    setIsShowPaymentModal,
    setIsShowAccommodationCardPrintingModal,
    setShowPreparationListForm,
    setRoomBookingPreparationList,
    changeDateTypeQuery,
    setHsUrlDetail,
    setHsEditHistory,
    setIsHsUrlDetailSync,
    setShowBulkPaymentGroupBookingModal,
} = roomBookingSlice.actions;

export const roomBookingListSelector = (state: AppState) => {
    return state.roomBooking.roomBookingList;
};
export const roomBookingStateSelector = (state: AppState) => {
    return state.roomBooking;
};
export const roomBookingReceiptSelector = (state: AppState) => {
    return state.roomBooking.roomBookingReceipt;
};
export const roomBookingListQuerySelector = (state: AppState) => {
    return state.roomBooking.roomBookingListQuery;
};
export const totalRoomBookingsSelector = (state: AppState) => {
    return state.roomBooking.totalRoomBookings;
};

export const canEditRoomBookingSelector = (state: AppState) => {
    return state.roomBooking.canEdit;
};

export const totalPageSelector = (state: AppState) => {
    const { totalRoomBookings, roomBookingListQuery } = state.roomBooking;
    const { limit = DEFAULT_LIMIT_FOR_PAGINATION } = roomBookingListQuery;
    return Math.ceil(totalRoomBookings / limit);
};

export const isShowReservationFormSelector = (state: AppState) => {
    return state.roomBooking.isShowReservationForm;
};

export const isShowAdvanceSearchFormSelector = (state: AppState) => {
    return state.roomBooking.isShowAdvanceSearchForm;
};

export const isShowAdvanceSearchTagsSelector = (state: AppState) => {
    return state.roomBooking.isShowAdvanceSearchTags;
};

export const advanceSearchListQuerySelector = (state: AppState) => {
    return state.roomBooking.advanceSearchGetListQuery;
};

export const marketingChannelDropDownsSelector = (state: AppState) => {
    return state.roomBooking.marketingChannelDropDowns;
};

export const showLoadingSelector = (state: AppState) => {
    return state.roomBooking.showLoading;
};

export const searchFieldSelector = (state: AppState) => {
    return state.roomBooking.searchField;
};

export const isAdvanceSearchSelector = (state: AppState) => {
    return state.roomBooking.isAdvanceSearch;
};

export const isUpdatingBookingSelector = (state: AppState) => {
    return state.roomBooking.isUpdatingBooking;
};
export const selectedRoomBookingDetailSelector = (state: AppState) => {
    return state.roomBooking.selectedRoomBookingDetail;
};

export const isHsUrlDetailSyncSelector = (state: AppState) => {
    return state.roomBooking.isHsUrlDetailSync;
};

export const isShowAccommodationCardPrintingModalSelector = (state: AppState) => {
    return state.roomBooking.isShowAccommodationCardPrintingModal;
};

export const showPreparationListFormSelector = (state: AppState) => {
    return state.roomBooking.showPreparationListForm;
};

export const roomBookingPreparationListSelector = (state: AppState) => {
    return state.roomBooking.roomBookingPreparationList;
};

export const showLoadingPreparationListTableSelector = (state: AppState) => {
    return state.roomBooking.showLoadingPeparationListTable;
};

export const bookingSearchKeywordSelector = (state: AppState) => {
    return state.roomBooking.bookingSearchKeyword;
};

export const isShowInputSearchSelector = (state: AppState) => {
    return state.roomBooking.isShowInputSearch;
};

export const dateTypeBookingSearchSelector = (state: AppState) => {
    return state.roomBooking.dateTypeBookingSearch;
};

export const bookingIteamSearchStatusSelector = (state: AppState) => {
    return state.roomBooking.bookingIteamSearchStatus;
};
export const hsUrlDetailSelector = (state: AppState) => {
    return state.roomBooking.hsRoomBookingUrlDetail;
};
export const hsEditHistorySelector = (state: AppState) => {
    return state.roomBooking.hsEditHistory;
};

export const activeDetailTabPaneSelector = (state: AppState) => {
    return state.roomBooking.activeDetailTabPane;
};

export const showBulkPaymentGroupBookingModal = (state: AppState) => {
    return state.roomBooking.isShowBulkPaymentGroupBookingModal;
};
export default roomBookingSlice.reducer;
