import { ApiService } from '~plugins/axios/api';
import { axiosInstanceV2 } from '~plugins/axios';
import { IBodyResponseV2 } from '~common/interfaces';
import {
    IMarketingChannelCredentialBody,
    IMarketingChannelCredential,
} from '../interfaces';
class MarketingChannelCredentialService extends ApiService {
    getMarketingChannelCredentialByMarketingId(marketingChannelId: number) {
        return this._get(`${this.baseUrl}/${marketingChannelId}/credential`) as Promise<
            IBodyResponseV2<IMarketingChannelCredential>
        >;
    }

    create(marketingChannelId: number, body: IMarketingChannelCredentialBody) {
        return this._post(
            `${this.baseUrl}/${marketingChannelId}/credential`,
            body,
        ) as Promise<IBodyResponseV2<IMarketingChannelCredential>>;
    }

    update(
        marketingChannelId: number,
        credentialId: number,
        body: IMarketingChannelCredentialBody,
    ) {
        return this._patch(
            `${this.baseUrl}/${marketingChannelId}/credential/${credentialId}`,
            body,
        ) as Promise<IBodyResponseV2<IMarketingChannelCredential>>;
    }
}

export const marketingChannelCredentialService = new MarketingChannelCredentialService(
    { baseUrl: '/marketing-channel' },
    axiosInstanceV2,
);
