import {
    Button,
    Card,
    Form,
    Layout,
    notification,
    Radio,
    RadioChangeEvent,
    Spin,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    fetchRoomBookingDetail,
    showLoadingSelector,
    selectedRoomBookingDetailSelector,
    setActiveDetailTabPane,
} from '~features/room-booking/reducers/room-booking.reducer';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '~hooks';
import './OTACancelationRobotPage.scss';
import { StaffLayoutHeader, InputTextArea } from '~components';

import { getAutoGeneratedCode } from '~features/room-booking/helper';
import OTACancelationRobotModal from './components/OTACancelationRobotModal/OTACancelationRobotModal';
import { getMarketingChannelCredential } from '~features/marketing-channel-credential/reducers/marketing-channel-credential.reducer';
import { roomBookingServiceV2 } from '~features/room-booking/services/room-bookingV2.service';
import { useMutation } from '@tanstack/react-query';
import { ICancelOtaBookingBody } from '~features/room-booking/interfaces';
import {
    RoomBookingDetailPageTabPane,
    TABIST_MARKETING_CHANNEL_LINKAGE_CODE,
} from '~features/room-booking/constants';

const { Content } = Layout;

function OTACancelationRobotPage() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoading = useAppSelector(showLoadingSelector);
    const { roomBookingId } = useParams();
    const parsedRoomBookingId = Number(roomBookingId);
    const selectedRoomBookingDetail = useAppSelector(selectedRoomBookingDetailSelector);
    const { credential, loading: credentialLoading } = useAppSelector(
        (state) => state.marketingChannelCredential,
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cancellationRate, setCancellationRate] = useState<0 | 100>(0);
    const onChange = (e: RadioChangeEvent) => {
        setCancellationRate(e.target.value);
    };
    const { control, handleSubmit } = useForm();

    const breadcrumbs = () => [
        {
            text: t('roomBooking.list.breadcrumbs.home'),
        },
        {
            text: t('roomBooking.list.breadcrumbs.roomBooking'),
        },
        {
            text: t('roomBooking.detail.breadcrumbs.OtaCancelationRobot'),
        },
    ];

    const _getDetail = useCallback(async (id: number) => {
        await dispatch(fetchRoomBookingDetail(id));
    }, []);

    const _getMarketingChannelCredentialDetail = useCallback(async (id: number) => {
        await dispatch(getMarketingChannelCredential(id));
    }, []);

    useEffect(() => {
        if (isNaN(parsedRoomBookingId)) {
            navigate('/404');
        }
        _getDetail(parsedRoomBookingId);
    }, [roomBookingId]);

    const { mutateAsync, isPending } = useMutation({
        mutationFn: async (data: ICancelOtaBookingBody) => {
            if (!selectedRoomBookingDetail) throw new Error('Missing booking');
            const response = await roomBookingServiceV2.cancelOtaBooking(
                selectedRoomBookingDetail.id,
                data,
            );
            if (response.success) {
                return response.data;
            }
            throw new Error(response.message);
        },
        onSuccess: () => {
            notification.success({
                message: t('roomBooking.detail.message.otaBookingCancelSuccess'),
            });
            if (!selectedRoomBookingDetail) return;
            dispatch(
                setActiveDetailTabPane(
                    RoomBookingDetailPageTabPane.ROOM_BOOKING_CANCELATION_INFO,
                ),
            );
            navigate(`/room-booking/${selectedRoomBookingDetail?.id}/detail`);
        },
        onError: (error) => {
            notification.error({
                message: t('roomBooking.detail.message.otaBookingCancelFailed'),
                description: error.message,
            });
        },
    });

    useEffect(() => {
        if (selectedRoomBookingDetail?.marketingChannelId) {
            _getMarketingChannelCredentialDetail(
                selectedRoomBookingDetail?.marketingChannelId,
            );
        }
    }, [selectedRoomBookingDetail?.marketingChannelId]);

    const cancelBooking = () => {
        handleSubmit(async (data) => {
            const { memo } = data;
            await mutateAsync({
                otaCancellationRate: cancellationRate,
                otaCancellationMemo: memo,
                roomBookingIds: selectedRoomBookingDetail?.roomBookingItems?.map(
                    (item) => item.id,
                ) as number[],
            });
        })();
    };

    return (
        <div className="cancelation-robot">
            <Spin spinning={isLoading || credentialLoading}>
                <StaffLayoutHeader
                    breadcrumbs={breadcrumbs()}
                    title={t('roomBooking.detail.breadcrumbs.OtaCancelationRobot')}
                />
                <Content>
                    <div className="cancelation-robot__card-wrapper">
                        <Card
                            className="cancelation-robot__card"
                            title={t('roomBooking.detail.otaCancelation.cancelResId', {
                                resId: getAutoGeneratedCode(
                                    selectedRoomBookingDetail?.autoGeneratedCode || '',
                                ),
                            })}
                        >
                            <Form>
                                <div className="cancelation-robot__card-content">
                                    <div className="cancelation-robot__data">
                                        <div>
                                            {t('roomBooking.detail.guestInfo.name')}:
                                        </div>
                                        <div className="cancelation-robot__data-value">
                                            {
                                                selectedRoomBookingDetail?.reserverGuest
                                                    ?.yomigana
                                            }
                                        </div>
                                        <div className="cancelation-robot__data-value">
                                            {
                                                selectedRoomBookingDetail?.reserverGuest
                                                    ?.fullName
                                            }
                                        </div>
                                    </div>
                                    <div className="cancelation-robot__data">
                                        <div>OTA:</div>
                                        <div className="cancelation-robot__data-value">
                                            {
                                                selectedRoomBookingDetail
                                                    ?.marketingChannel?.name
                                            }
                                        </div>
                                    </div>
                                    {selectedRoomBookingDetail?.marketingChannel
                                        ?.linkageCode !==
                                        TABIST_MARKETING_CHANNEL_LINKAGE_CODE && (
                                        <div className="cancelation-robot__data">
                                            <div>
                                                {t(
                                                    'roomBooking.detail.otaCancelation.AdminSetting',
                                                )}
                                                :
                                            </div>
                                            <div className="cancelation-robot__data-value">
                                                {credential
                                                    ? t(
                                                          'roomBooking.detail.otaCancelation.completed',
                                                      )
                                                    : t(
                                                          'roomBooking.detail.otaCancelation.notComplete',
                                                      )}
                                            </div>
                                            <div>
                                                <Button
                                                    size="small"
                                                    type="link"
                                                    onClick={() => setIsModalOpen(true)}
                                                >
                                                    {t(
                                                        'roomBooking.detail.otaCancelation.AdminSettingBtn',
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    <div className="cancelation-robot__input">
                                        <div>
                                            {t('roomBooking.detail.otaCancelation.fee')}:
                                        </div>
                                        <Radio.Group
                                            onChange={onChange}
                                            value={cancellationRate}
                                        >
                                            <Radio value={0}>
                                                {t(
                                                    'roomBooking.detail.otaCancelation.free',
                                                )}
                                            </Radio>
                                            <Radio value={100}>
                                                {t(
                                                    'roomBooking.detail.otaCancelation.paid',
                                                )}
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                    <div className="cancelation-robot__input">
                                        <div>
                                            {t(
                                                'roomBooking.detail.otaCancelation.memoLabel',
                                            )}
                                        </div>
                                        <InputTextArea
                                            label=""
                                            placeholder={t(
                                                'roomBooking.detail.otaCancelation.memoPlaceholder',
                                            )}
                                            name="memo"
                                            control={control}
                                            id="ota-cancelation-form-memo"
                                            allowClear
                                            rows={3}
                                        />
                                    </div>
                                    <div className="cancelation-robot__data">
                                        <Button
                                            size="large"
                                            className="cancelation-robot__action-btn"
                                            onClick={() =>
                                                navigate(
                                                    `/room-booking/${roomBookingId}/detail`,
                                                )
                                            }
                                        >
                                            {t(
                                                'roomBooking.detail.otaCancelation.cancelBtn',
                                            )}
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            danger
                                            loading={isPending}
                                            disabled={
                                                !credential &&
                                                selectedRoomBookingDetail
                                                    ?.marketingChannel?.linkageCode !==
                                                    TABIST_MARKETING_CHANNEL_LINKAGE_CODE
                                            }
                                            className="cancelation-robot__action-btn"
                                            onClick={cancelBooking}
                                        >
                                            {t(
                                                'roomBooking.detail.otaCancelation.submitBtn',
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Card>
                    </div>
                    {isModalOpen && (
                        <OTACancelationRobotModal
                            isModalOpen={isModalOpen}
                            setIsModalOpen={setIsModalOpen}
                        />
                    )}
                </Content>
            </Spin>
        </div>
    );
}

export default OTACancelationRobotPage;
